<template>
    <div class="user-index">
        <div class="main-h1">Пользователи</div>
        <div class="card">
            <div class="card-body">
                <div class="card-body-title">Список пользователей</div>
                <table class="table">
                    <thead>
                    <tr>
                        <th class="media-only-desktop">id</th>
                        <th>БИН/ИИН/ОГРН:</th>
                        <th class="media-only-desktop">
                            Имя/наименование
                            <input @keyup="filterUsers" v-model="filterName"/>
                        </th>
                        <th>Город</th>
                        <th class="media-only-desktop">Форма</th>
                        <th class="media-only-desktop">Email</th>
                        <th class="media-only-desktop">Номер телефона</th>
                        <th>Роли</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="user in listFiltered">
                        <td class="media-only-desktop">{{user.id}}</td>
                        <td>{{user.login}}</td>
                        <td class="media-only-desktop">{{user.name}}</td>
                        <td>{{user.city_title}}</td>
                        <td class="media-only-desktop">{{user.type}}</td>
                        <td class="media-only-desktop">{{user.email}}</td>
                        <td class="media-only-desktop">{{user.phone}}</td>
                        <td>
                            {{user.roles.map((item) => item.title).join(', ')}}
                        </td>
                        <td class="controls flex-st">
                            <router-link :to="`/panel/user/${user.id}/edit`" class="item edit"><i class="material-icons">edit</i></router-link>
                            <router-link v-if="user.id > 0" :to="`/panel/user/${user.id}/view`" class="item list"><i class="material-icons">featured_play_list</i></router-link>
                            <div v-if="user.id > 0" class="item delete"><i class="material-icons" @click="deleteUser(user.id)">delete</i></div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <router-link to="/panel/user/create" class="btn green">Создать пользователя</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            list:[],
            listFiltered:[],
            filterName: '',
        }
    },
    methods: {
        getList() {
            this.$api.get(`user`).then(res => {
                if (res.data.success) {
                    this.list = res.data.data
                    this.listFiltered = res.data.data
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        deleteUser(user_id) {
            this.$api.delete(`user/${user_id}`).then(res => {
                this.requestInProgress = false;
                if (res.data.success) {
                    this.$store.commit('showNotify',{title:'Успех',content:'Пользователь удален.',type:'success'})
                    this.getList()
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'})
                }
            })
        },
        filterUsers() {
            this.listFiltered = this.list.filter(item => {
                return item.name.toLowerCase().includes(this.filterName.toLowerCase());
            })
        }
    },
    created() {
        this.getList();
    }
}
</script>

<style scoped>
    .user-index {

    }
</style>